<template>
  <v-container fluid class="pa-6">
    <v-row align="center" justify="center">
      <v-col md="6">
        <v-card>
          <v-container class="text-center pa-6">
            <v-icon class="mb-2" large color="app-green">mdi-check</v-icon>
            <h4 class="text-h5 text-center mb-3 font-weight-light">
              {{ $t('auth.successfulRegistrationTitle') }}
            </h4>
            <p>
              {{ $t('auth.successfulRegistrationText', { email }) }}
            </p>
            <p>
              {{ $t('auth.resendEmailForActivationText') }}
            </p>
            <v-btn
              dark
              color="app-blue"
              :loading="loading"
              @click="sendNewActivationEmail()"
              >{{ $t('auth.resendEmailForActivationBtn') }}</v-btn
            >
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { RESEND_NEW_ACTIVATION_EMAIL } from '../store/auth/types';
export default {
  name: 'successful-sign-up',
  data() {
    return {
      email: '',
      loading: false
    };
  },
  created() {
    this.email = this.$route.query.email;
  },
  methods: {
    async sendNewActivationEmail() {
      this.loading = true;

      await this.$store.dispatch(RESEND_NEW_ACTIVATION_EMAIL, {
        email: this.email
      });

      this.loading = false;

      this.$notify({
        type: 'success',
        text: this.$i18n.t('auth.newActivationEmailSent')
      });
    }
  }
};
</script>
